import {
  MantineProvider,
  Stepper as MStepper,
  TransferList as MTransferList,
  TransferListData,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import {
  FaCalendarAlt,
  FaCalendarWeek,
  FaHandPointer,
  FaInfinity,
  FaSync,
  FaTimesCircle,
  FaUser,
} from "react-icons/fa";
import { useMutation } from "react-query";
import { postAccessKey } from "src/common/api";
import colors from "src/common/colors";
import { HttpCode } from "src/common/enums";
import { ActionIconTooltip } from "src/common/features";
import {
  useAccessKeys,
  useAccessPoints,
  useCarOptions,
  useClusterInfo,
  useUsersQuery,
} from "src/common/hooks";
import { accessKeySchema } from "src/common/schemas";
import { useAccessKeyStore } from "src/common/store";
import {
  getYears,
  httpErrorMessage,
  objectValuesCheck,
  undefinedIfEmpty,
} from "src/common/utils";
import {
  AccessKeyScheduleInput,
  Button,
  ChipsInputGroup,
  ColorInput,
  DateRangePickerInput,
  DayPicker,
  Drawer,
  SelectInput,
  TextInput,
  TimeRangeInput,
  Typography,
} from "src/components";
import { CreateUserDrawer } from "src/pages/users/features/drawers";
import { VehicleSearchPopover } from "src/pages/users/features/modals";

const initialValues = (
  accessPoints: any[],
  accessForm: any,
): TransferListData => [
  accessPoints
    .filter(
      (accessPoint: any) => accessPoint.toe === accessForm.values.entryType,
    )
    .map((accessPoint: any) => {
      return {
        label: accessPoint.name,
        value: accessPoint.id,
      };
    }),
  [],
];

/** Verifies if provided object contains properties deemed as errors. Can accept
 * a specific property as second argument. */
function errorChecker(formError: any, objKey?: string): boolean {
  if (objKey) return Object.keys(formError).includes(objKey);
  return Object.keys(formError).length > 0;
}

export const CreateAccessKeyDrawer = ({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);

  const accessPoints = useAccessPoints();
  const {
    data: usersData,
    isSuccess: usersSuccess,
    refetch: usersRefetch,
    isFetching: usersIsFetching,
  } = useUsersQuery();
  const carOptions = useCarOptions();

  const [queryOptions] = useAccessKeyStore();
  const { refetch: accessKeysRefetch } = useAccessKeys(queryOptions);

  const { mutate: submitPostAccessKey, isLoading: postAccessKeyLoading } =
    useMutation(postAccessKey, {
      onSuccess: (res) => {
        accessForm.reset();
        setOpenDrawer(false);
        resetSteps();
        showNotification({
          title: "Success",
          message:
            res.statusCode === HttpCode.ACCEPTED
              ? "Access key was created, but not sent to control panel."
              : "Access Key was created.",
          color: res.statusCode === HttpCode.ACCEPTED ? "yellow" : "green",
        });
        accessKeysRefetch();
      },
      onError: (res: AxiosError) => {
        // NOTE to not close the drawer until errors resolved
        // setOpenDrawer(false);
        showNotification({
          title: "Error",
          message: httpErrorMessage(res, {
            unprocessable_entity: "Key already exists in all Access Points.",
          }),
          color: "red",
          autoClose: false,
        });
      },
    });

  const resetSteps = () => {
    setActiveStep(0);
    accessForm.reset();
    setTransferList(initialValues(accessPoints, accessForm));
  };

  const accessForm = useForm({
    schema: zodResolver(accessKeySchema),
    initialValues: {
      entryType: "RFID" as "RFID" | "QRCODE",
      locationIds: [] as { id: string; value: string }[] | any[],
      type: "ALWAYSACTIVE" as
        | "ALWAYSACTIVE"
        | "BYPERIOD"
        | "BYSCHEDULE"
        | "BYUSES",
      userId: "",
      key: "",
      vehicleId: "",
      vehicle: {
        carBrand: "",
        carModel: "",
        color: "",
        licensePlate: "",
        year: "",
      },
      periodFrom: dayjs().toDate(),
      periodTo: dayjs().toDate(),
    },
  });

  const cluster = useClusterInfo();
  const [transferList, setTransferList] = useState<TransferListData>(
    initialValues(accessPoints, accessForm),
  );

  const [carFromSearch, setCarFromSearch] = useState<any>();
  const [openedPopover, setOpenedPopover] = useState<boolean>(false);
  const handleSetItem = (item: any) => {
    accessForm.setFieldValue("vehicleId", item.id);
    setCarFromSearch(item);
  };

  const disableCarFields = accessForm.values.vehicleId.length > 0;

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
        resetSteps();
      }}
      position="right"
      // size="45%"
      size={openEditUserDrawer ? "35%" : "30%"}
      withCloseButton
    >
      <form
        id="accessForm"
        noValidate
        onSubmit={accessForm.onSubmit((data) => {
          const body = {
            ...data,
            clusterId: cluster.id,
            userId: undefinedIfEmpty(data.userId),
            vehicleId: undefinedIfEmpty(data.vehicleId),
            vehicle: objectValuesCheck({
              ...data.vehicle,
              year: parseInt(data.vehicle.year),
            }),
            locationIds: data.locationIds.map((locationId) => locationId.value),
          };
          submitPostAccessKey(body);
        })}
      >
        <Drawer.Header title="Give Access" />

        <MStepper
          active={activeStep}
          onStepClick={setActiveStep}
          breakpoint="md"
          orientation="vertical"
          color="teal"
        >
          <MStepper.Step
            color={errorChecker(accessForm.errors, "key") ? "red" : "teal"}
            completedIcon={errorChecker(accessForm.errors, "key") && <BsXLg />}
            label={
              <Typography state={activeStep >= 0 ? "info" : undefined}>
                Step 1
              </Typography>
            }
            description="Select Access Key type"
          >
            <div className="flex flex-col space-y-5">
              <Typography
                type="header"
                state="info"
                className="flex justify-center"
              >
                Provide the type of Access Key
              </Typography>
              <ChipsInputGroup
                // {...accessForm.getInputProps("entryType", {
                //   type: "checkbox",
                // })}
                value={accessForm.values.entryType}
                onChange={(value) =>
                  accessForm.setFieldValue("entryType", value)
                }
                // chipsOnClick={() => setActiveStep(1)}
                chips={[
                  {
                    value: "RFID",
                    children: "RFID",
                  },
                  {
                    value: "QRCODE",
                    children: "QR CODE",
                    disabled: true,
                  },
                ]}
              />
              {accessForm.values.entryType === "RFID" && (
                <div className="mt-1 mb-3">
                  <TextInput
                    required
                    label="RFID keys require the number found on a RFID / AVI tag"
                    placeholder="Key (xxx-xxxxx)"
                    value={accessForm.values.key}
                    pattern="^[0-9]{3}-[0-9]{5}$"
                    maxLength={9}
                    disabled={accessForm.values.entryType !== "RFID"}
                    onChange={(e) => {
                      accessForm.setFieldValue("key", e.target.value);
                    }}
                    error={accessForm.errors.key}
                  />
                </div>
              )}
              <div className="flex grow justify-center">
                <Button
                  text="Next"
                  onClick={() => setActiveStep((value) => value + 1)}
                />
              </div>
              {/* )} */}
            </div>
          </MStepper.Step>

          <MStepper.Step
            color={
              errorChecker(accessForm.errors, "locationIds") ? "red" : "teal"
            }
            completedIcon={
              errorChecker(accessForm.errors, "locationIds") && <BsXLg />
            }
            label={
              <Typography state={activeStep >= 1 ? "info" : undefined}>
                Step 2
              </Typography>
            }
            description="Select Access Points"
          >
            <Typography
              type="header"
              state="info"
              label="Access Key will be sent to the selected Access Points"
              labelPosition="bottom"
              className="mb-3 flex flex-col justify-center text-center"
              // className="flex flex-col-reverse text-center justify-center mb-3"
            >
              Select at least one Access Point.
            </Typography>
            {errorChecker(accessForm.errors, "locationIds") && (
              <Typography
                state="error"
                className="mb-2 flex justify-center text-center"
              >
                {accessForm.errors.locationIds}. Access Points on
                &quot;Selected&quot; column are considered as selected.
              </Typography>
            )}

            <MantineProvider
              // UGLY search for better implementation of "global" styles
              withGlobalStyles
              withNormalizeCSS
              theme={{
                primaryShade: 0, // Determines which of the list will be used
                colors: {
                  brand: [colors.primary],
                },
                primaryColor: "brand",
              }}
            >
              <MTransferList
                searchPlaceholder="Search..."
                titles={["Available", "Selected"]}
                value={transferList}
                classNames={{
                  transferListTitle: "text-center",
                }}
                nothingFound={
                  transferList[1].length // Selected list contents
                    ? "All Access Points were selected"
                    : "Nothing selected yet"
                }
                onChange={(values) => (
                  setTransferList(values),
                  accessForm.setFieldValue("locationIds", values[1])
                )}
              />
            </MantineProvider>

            <div className="flex grow content-end justify-evenly p-8">
              <Button
                text="Prev"
                variant="outlined"
                onClick={() => setActiveStep((value) => value - 1)}
              />
              <Button
                text="Next"
                onClick={() => setActiveStep((value) => value + 1)}
              />
            </div>
          </MStepper.Step>

          <MStepper.Step
            label={
              <Typography state={activeStep >= 2 ? "info" : undefined}>
                Step 3
              </Typography>
            }
            description="Select Access Key schedule"
          >
            <div className="flex flex-col gap-3">
              <Typography
                type="header"
                state="info"
                className="mb-3 flex justify-center"
              >
                Determine the availability
              </Typography>
              <div className="flex gap-3">
                <AccessKeyScheduleInput
                  icon={<FaInfinity size={"1.5rem"} />}
                  label="Always"
                  checked={accessForm.values.type === "ALWAYSACTIVE"}
                  onChange={() => {
                    // setActiveStep(3);
                    accessForm.setFieldValue("type", "ALWAYSACTIVE");
                  }}
                />
                <AccessKeyScheduleInput
                  icon={<FaCalendarAlt size={"1.5rem"} />}
                  label="By Period"
                  checked={accessForm.values.type === "BYPERIOD"}
                  disabled
                  onChange={() => {
                    // setActiveStep(2);
                    accessForm.setFieldValue("type", "BYPERIOD");
                  }}
                />
                <AccessKeyScheduleInput
                  icon={<FaCalendarWeek size={"1.5rem"} />}
                  label="By Schedule"
                  checked={accessForm.values.type === "BYSCHEDULE"}
                  disabled
                  onChange={() => {
                    // setActiveStep(2);
                    accessForm.setFieldValue("type", "BYSCHEDULE");
                  }}
                />
                <AccessKeyScheduleInput
                  icon={<FaHandPointer size={"1.5rem"} />}
                  label="Until"
                  checked={accessForm.values.type === "BYUSES"}
                  disabled
                  onChange={() => {
                    // setActiveStep(2);
                    accessForm.setFieldValue("type", "BYUSES");
                  }}
                />
              </div>

              <div className="">
                {accessForm.values.type === "ALWAYSACTIVE" && (
                  <Typography
                    className="flex justify-center"
                    state="info"
                    type="header"
                  >
                    With this option, the key will have access 24/7
                  </Typography>
                )}

                {accessForm.values.type === "BYPERIOD" && (
                  <>
                    <Typography state="info">Set date range:</Typography>
                    <DateRangePickerInput
                      placeholder="mm/dd/yyyy - mm/dd/yyyy"
                      onChange={() => {
                        setActiveStep(3);
                      }}
                    />
                  </>
                )}

                {accessForm.values.type === "BYSCHEDULE" && (
                  <>
                    <div className="mb-3">
                      <Typography state="info">Select days:</Typography>
                      <div className="flex gap-3">
                        <DayPicker label="Mon" checked />
                        <DayPicker label="Tus" checked />
                        <DayPicker label="Wed" checked />
                        <DayPicker label="Thu" checked />
                        <DayPicker label="Fri" checked />
                        <DayPicker label="Sat" checked />
                        <DayPicker label="Sun" checked />
                      </div>
                    </div>

                    <div>
                      <Typography state="info">Set time period:</Typography>
                      <TimeRangeInput
                        onChange={() => {
                          setActiveStep(3);
                        }}
                      />
                    </div>
                  </>
                )}

                {accessForm.values.type === "BYUSES" && (
                  <>
                    <Typography state="info">
                      Amount of times the key can be used:
                    </Typography>
                    <TextInput
                      type="number"
                      placeholder="###"
                      onChange={() => {
                        setActiveStep(3);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex grow content-end justify-evenly p-8">
              <Button
                text="Prev"
                variant="outlined"
                onClick={() => setActiveStep((value) => value - 1)}
              />
              <Button
                text="Next"
                onClick={() => setActiveStep((value) => value + 1)}
              />
            </div>
          </MStepper.Step>

          <MStepper.Step
            label={
              <Typography state={activeStep >= 3 ? "info" : undefined}>
                Step 4 (optional)
              </Typography>
            }
            description="Select or Create User"
          >
            <div className="flex flex-col gap-3">
              <CreateUserDrawer
                openDrawer={openEditUserDrawer}
                setOpenDrawer={setOpenEditUserDrawer}
                hideCarsSection
              />
              <Typography
                type="header"
                state="info"
                className="text-center"
                label="This step is optional. Can be edited once Access Key is created."
                labelPosition="bottom"
              >
                User for Access Key
              </Typography>
              <div className="p-1" />

              <div className="flex justify-around gap-3">
                <ActionIconTooltip
                  iconColor="green"
                  tooltipText={
                    usersIsFetching ? "Fetching" : "Refresh User List"
                  }
                  loading={usersIsFetching}
                  icon={
                    <div className="flex flex-row gap-1">
                      <FaSync />
                      <FaUser />
                    </div>
                  }
                  onClick={() => usersRefetch()}
                />
                <SelectInput
                  className="grow"
                  options={
                    usersSuccess && usersData
                      ? usersData.users.map((user: any) => {
                          return {
                            label: `${user.fname} ${user.lname} ${user.lname2}`,
                            value: user.id,
                          };
                        })
                      : [{ label: "error", value: "error" }]
                  }
                  value={accessForm.values.userId}
                  onChange={(value) => {
                    accessForm.setFieldValue("userId", value);
                  }}
                  creatable
                  getCreateLabel={(query) => `+ Create '${query}'`}
                  onCreate={() => {
                    setOpenEditUserDrawer(true);
                    accessForm.setFieldValue("userId", "");
                  }}
                />
                <ActionIconTooltip
                  disabled={!accessForm.values.userId}
                  className="bg-error"
                  iconColor="red"
                  tooltipText="Clear User"
                  icon={<FaTimesCircle />}
                  onClick={() => accessForm.setFieldValue("userId", "")}
                />
              </div>
            </div>
            <div className="flex grow content-end justify-evenly p-8">
              <Button
                text="Prev"
                variant="outlined"
                onClick={() => setActiveStep((value) => value - 1)}
              />
              <Button
                text="Next"
                onClick={() => setActiveStep((value) => value + 1)}
              />
            </div>
          </MStepper.Step>

          <MStepper.Step
            label={
              <Typography state={activeStep >= 4 ? "info" : undefined}>
                Step 5 (optional)
              </Typography>
            }
            description="Select or Create Vehicle"
          >
            <div className="flex flex-col gap-3">
              <Typography
                type="header"
                state="info"
                className="text-center"
                label="This step is optional. Can be edited once Access Key is created."
                labelPosition="bottom"
              >
                Provide vehicle details
              </Typography>
              <div className="p-1" />
              <div className="flex gap-2">
                <VehicleSearchPopover
                  popoverOpened={openedPopover}
                  setPopoverOpened={setOpenedPopover}
                  setItem={handleSetItem}
                />
                {/* 
                { "value": "TST-001", "id": "cl595pxvr237l07655sdxebdw",
                "plate": "TST-001", "color": "#b30e16", "model": "Beetle",
                "brand": "Volkswagen", "year": 2006, "rfid": "001-11111" }
                 */}
                <Typography>
                  {carFromSearch &&
                    `${carFromSearch?.plate} - ${carFromSearch?.brand} ${carFromSearch?.model} ${carFromSearch?.year}`}
                  {/* {JSON.stringify(carFromSearch, null, 2)} */}
                </Typography>
              </div>

              <div className="flex flex-row gap-3">
                <SelectInput
                  required
                  disabled={disableCarFields}
                  className="grow"
                  label="Brand"
                  nothingFound="No options"
                  placeholder="Brand"
                  options={
                    carOptions.isSuccess && carOptions.data
                      ? carOptions.data.carBrands.map((brand: any) => {
                          return `${brand.name}`;
                        })
                      : []
                  }
                  value={accessForm.values.vehicle.carBrand}
                  onChange={(value) => {
                    // accessForm.setFieldValue("vehicle", value);
                    accessForm.setFieldValue("vehicle", {
                      ...accessForm.values.vehicle,
                      carBrand: value,
                    });
                  }}
                />
                <SelectInput
                  required
                  disabled={disableCarFields}
                  className="grow"
                  label="Model"
                  nothingFound={`${
                    accessForm.values.vehicle.carBrand
                      ? "No Options"
                      : "Must choose a brand first"
                  }`}
                  placeholder="Model"
                  // disabled={accessForm.values.vehicle.carBrand === " "}
                  /*eslint-disable*/
                  options={
                    !carOptions.data
                      ? []
                      : [
                          ...carOptions.data?.carBrands
                            .filter(
                              (carBrand: any) =>
                                carBrand.name ===
                                accessForm.values.vehicle.carBrand,
                            )
                            .map((carBrand: any) => {
                              return carBrand?.brandModels?.map(
                                (model: any) => {
                                  return model.name;
                                },
                              );
                            })
                            .flat(),
                        ]
                  }
                  /*eslint-enable*/
                  value={accessForm.values.vehicle.carModel}
                  onChange={(value) => {
                    accessForm.setFieldValue("vehicle", {
                      ...accessForm.values.vehicle,
                      carModel: value,
                    });
                  }}
                />
              </div>

              <div className="flex gap-2">
                <SelectInput
                  required
                  disabled={disableCarFields}
                  placeholder="Year"
                  label="Year"
                  nothingFound="Invalid year"
                  options={getYears().map((year) => year.toString())}
                  value={accessForm.values.vehicle.year}
                  onChange={(value) => {
                    accessForm.setFieldValue("vehicle", {
                      ...accessForm.values.vehicle,
                      year: value,
                    });
                  }}
                />

                <TextInput
                  required
                  disabled={disableCarFields}
                  label="Plate"
                  placeholder="Plate (TST-001)"
                  value={accessForm.values.vehicle.licensePlate}
                  onChange={(e) => {
                    accessForm.setFieldValue("vehicle", {
                      ...accessForm.values.vehicle,
                      licensePlate: e.target.value,
                    });
                  }}
                />
                <ColorInput
                  required
                  disabled={disableCarFields}
                  label="Color"
                  value={accessForm.values.vehicle.color}
                  onChange={(value) => {
                    accessForm.setFieldValue("vehicle", {
                      ...accessForm.values.vehicle,
                      color: value,
                    });
                  }}
                />
              </div>
              <div className="flex grow content-end justify-evenly p-3">
                <Button
                  text="Prev"
                  variant="outlined"
                  onClick={() => setActiveStep((value) => value - 1)}
                />
              </div>
              <Button
                disabled={
                  !objectValuesCheck(accessForm.values.vehicle) &&
                  !(accessForm.values.vehicleId.length > 0)
                }
                variant="outlined"
                text="Clear Vehicle"
                onClick={() => {
                  setCarFromSearch(undefined);
                  accessForm.setFieldValue("vehicleId", "");
                  accessForm.setFieldValue("vehicle", {
                    ...accessForm.values.vehicle,
                    color: "",
                    licensePlate: "",
                    carBrand: "",
                    carModel: "",
                    year: "",
                  });
                }}
              />
              {errorChecker(accessForm.errors) && (
                <Typography state="error" className="text-center">
                  Errors found, check required fields and their values are
                  correct.
                </Typography>
              )}
              <Button
                className="mt-5"
                loading={postAccessKeyLoading}
                text="Create Access Key"
                type="submit"
                form="accessForm"
              />
              {/* REVIEW must find a way to accept proper validation for nested value */}
              {/* REVIEW mantine 5.0.0 provides a way: https://mantine.dev/form/nested/#nested-object-values-validation*/}
              {/* {Object.keys(accessForm.errors).length > 0 && (
                <Typography className="text-center" state="error">
                  Errors found, please check that your entries are correct
                </Typography>
              )} */}
            </div>
          </MStepper.Step>
        </MStepper>
      </form>
    </Drawer>
  );
};
